import React from 'react';
import { GoShield } from '@react-icons/all-files/go/GoShield';

export default class Ratings extends React.Component {
  render() {
    return (
      <div className="ratings">
        <div className={this.props.ratings >= 1 ? 'filled' : 'empty'}>
          <GoShield
            alt={`shield icon used for ratings and is ${
              this.props.ratings >= 1 ? 'filled' : 'empty'
            }`}
          />
        </div>
        <div className={this.props.ratings >= 2 ? 'filled' : 'empty'}>
          <GoShield
            alt={`shield icon used for ratings and is ${
              this.props.ratings >= 2 ? 'filled' : 'empty'
            }`}
          />
        </div>
        <div className={this.props.ratings >= 3 ? 'filled' : 'empty'}>
          <GoShield
            alt={`shield icon used for ratings and is ${
              this.props.ratings >= 3 ? 'filled' : 'empty'
            }`}
          />
        </div>
        <div className={this.props.ratings >= 4 ? 'filled' : 'empty'}>
          <GoShield
            alt={`shield icon used for ratings and is ${
              this.props.ratings >= 4 ? 'filled' : 'empty'
            }`}
          />
        </div>
        <div className={this.props.ratings === 5 ? 'filled' : 'empty'}>
          <GoShield
            alt={`shield icon used for ratings and is ${
              this.props.ratings >= 5 ? 'filled' : 'empty'
            }`}
          />
        </div>
      </div>
    );
  }
}
