import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { classNames, withPrefix } from '../utils';
import '../sass/main.scss';
import Header from './Header';
import Footer from './Footer';
import ThemeContext from '../context/ThemeContext';

export default class Body extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    const pageTitle = `${
      _.get(this.props, 'pageContext.frontmatter.title', null) &&
      _.get(this.props, 'pageContext.frontmatter.title', null) + ' | '
    }${_.get(this.props, 'pageContext.site.siteMetadata.title', null)}`;
    this.state.title = pageTitle || 'Tinfoil My Life';
    this.shouldIndexPage = this.shouldIndexPage.bind(this);
  }

  shouldIndexPage() {
    return this.props.uri !== '/honeypot' && this.props.uri !== '/honeypot/';
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <React.Fragment>
            <Helmet>
              <title>{this.state.title}</title>
              <meta charSet="utf-8" />
              {this.shouldIndexPage() && <meta property="robots" content="index, follow" />}
              {!this.shouldIndexPage() && <meta name="robots" content="noindex" />}
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <meta
                name="description"
                content={
                  _.get(this.props, 'pageContext.frontmatter.excerpt', null) ||
                  _.get(this.props, 'pageContext.site.siteMetadata.description', null)
                }
              />
              {_.get(this.props, 'pageContext.site.siteMetadata.favicon', null) && (
                <link
                  rel="icon"
                  href={withPrefix(
                    _.get(this.props, 'pageContext.site.siteMetadata.favicon', null)
                  )}
                />
              )}
            </Helmet>
            <div id="site-wrap" className="site">
              <Header {...this.props} />
              <main id="content" className={classNames('site-content', { dark: theme.dark })}>
                {this.props.children}
              </main>
              <Footer {...this.props} />
            </div>
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  }
}
