// href="mailto:?subject=%post_title%&body=%encoded_post_url%" title="Email" rel="nofollow noopener" target="_blank"

import React from 'react';
import LazyImage from '../LazyImage';

export default class EmailShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button email-share-button"
        title="Share via email"
        href={`mailto:?subject=${this.props.title}&body=${this.props.encodedLocation}`}
        target="_blank"
        rel="noreferrer nofollow"
        aria-label="Share via email"
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962169-emailsocial.png',
            alt: 'Email'
          }}
        />
      </a>
    );
  }
}
