import React from 'react';
import _ from 'lodash';
import { getPages } from '../utils';
import BlogFeedItemFilter from './BlogFeedItemFilter';
import { PaginationNavigation } from '.';

export default class BlogFeedSection extends React.Component {
  render() {
    let section = _.get(this.props, 'section', null);
    let section_author = false;
    let section_category = false;
    // this was /blogs
    let posts_all = getPages(this.props.pageContext.pages, '/articles');
    let posts_sorted = _.orderBy(posts_all, 'frontmatter.date', 'desc');
    const posts_filtered = posts_sorted.filter(
      (post) => _.get(post, 'frontmatter.template', null) === 'post'
    );
    let show_recent = _.get(section, 'show_recent', null);
    let recent_count = _.get(section, 'recent_count', null);

    const limit = _.get(this.props, 'pageContext.limit', null);
    const skip = _.get(this.props, 'pageContext.skip', null);
    if (_.get(section, 'author', null)) {
      section_author = _.get(section, 'author', null);
    }
    if (_.get(section, 'category', null)) {
      section_category = _.get(section, 'category', null);
    }

    let postsTruncated = [];
    if (section_category) {
      const catPosts = _.cloneDeep(posts_filtered).filter((post) => {
        return post.frontmatter.categories.map((c) => c.id).indexOf(section_category.id) >= 0;
      });
      postsTruncated = catPosts.slice(skip, skip + limit);
    } else if (typeof skip === 'number' && limit) {
      postsTruncated = posts_filtered.slice(skip, skip + limit);
    } else if (!show_recent) {
      postsTruncated = posts_filtered;
    } else if (show_recent && recent_count < posts_filtered.length) {
      // show_recent === false || post_count < recent_count
      postsTruncated = posts_filtered.slice(0, recent_count);
    }
    return (
      <section className="section section--posts">
        {_.get(section, 'title', null) && (
          <div className="container container--md align-center">
            <h2 className="section__title">{_.get(section, 'title', null)}</h2>
          </div>
        )}
        <div className="container container--lg">
          <div className="flex flex--col-3">
            {postsTruncated.map((post, post_idx) => {
              return (
                <React.Fragment key={post_idx + '.1'}>
                  <BlogFeedItemFilter
                    key={post_idx}
                    {...this.props}
                    blog_feed_section={section}
                    post_page={post}
                    section_author={section_author}
                    section_category={section_category}
                  />
                </React.Fragment>
              );
            })}
          </div>
          {_.get(this.props, 'pageContext.numberOfPages', 1) > 1 && (
            <PaginationNavigation
              total={_.get(this.props, 'pageContext.numberOfPages')}
              perPage={_.get(this.props, 'pageContext.limit')}
              nextPath={_.get(this.props, 'pageContext.nextPagePath')}
              previousPath={_.get(this.props, 'pageContext.previousPagePath')}
              current={_.get(this.props, 'pageContext.humanPageNumber')}
              baseUrl={_.get(this.props, 'pageContext.url')}
            />
          )}
        </div>
      </section>
    );
  }
}
