import React from 'react';
import _ from 'lodash';
import MobileNavIcons from './MobileNavIcons';

import { Link, withPrefix, classNames } from '../utils';

export default class Action extends React.Component {
  render() {
    let action = _.get(this.props, 'action', null);
    const actionLabel = _.get(action, 'label', null);
    return (
      <Link
        to={withPrefix(_.get(action, 'url', null))}
        {...(_.get(action, 'new_window', null) ? { target: '_blank' } : null)}
        {...(_.get(action, 'new_window', null) || _.get(action, 'no_follow', null)
          ? {
              rel:
                (_.get(action, 'new_window', null) ? 'noopener ' : '') +
                (_.get(action, 'no_follow', null) ? 'nofollow' : '')
            }
          : null)}
        className={classNames({
          btn: _.get(action, 'style', null) !== 'link',
          'btn--secondary': _.get(action, 'style', null) === 'secondary',
          dark: this.props.darkMode,
          'dark-link': this.props.darkMode
        })}
      >
        {this.props.withIcon && <MobileNavIcons label={actionLabel} />}
        {actionLabel}
      </Link>
    );
  }
}
