import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ image, errorImage }) => (
  <div>
    <LazyLoadImage
      alt={image.alt}
      src={image.src} // use normal <img> attributes as props
      onError={(e) => {
        if (errorImage) {
          e.target.onError = null;
          e.target.src = errorImage.src;
          e.target.alt = errorImage.alt;
        }
      }}
      effect="blur"
    />
    {/* <span>{image.caption}</span> */}
  </div>
);

export default LazyImage;
