import React from 'react';
import LazyImage from '../LazyImage';

export default class ParlerShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button parler-share-button"
        title="Share to Parler"
        target="_blank"
        rel="noreferrer"
        href={`https://parler.com/new-post?message=${this.props.encodedTitle}&url=${this.props.encodedLocation}`}
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962161-parlersocial.png',
            alt: 'Parler'
          }}
        />
      </a>
    );
  }
}
