import React from 'react';
import { Ratings } from '.';

export default class BlogDifficultyBadge extends React.Component {
  render() {
    if (!this.props.post?.id) {
      return <></>;
    } else {
      return (
        <div className={`blog-difficulty-badge ${this.props.post.id}`}>
          <div className="blog-difficulty-badge--advice">
            Difficulty rating for the advice in the article:
          </div>
          <div className="blog-difficulty-badge--label">
            {this.props.post.difficulty_title}
            <div className="blog-difficulty-badge--label__rating">
              {/* <Ratings ratings={this.props.post.numeric_id} /> */}
              <Ratings ratings={this.props.post.numeric_id} />
            </div>
          </div>
        </div>
      );
    }
  }
}
