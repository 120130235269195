import _ from 'lodash';
import { withPrefix } from '../utils';

export const ignorepaths = [
  '404',
  'about',
  'contact',
  'features',
  'help-me-out',
  'follow',
  'honeypot',
  'index',
  'search',
  'style-guide',
  'terms',
  '/blog/',
  '/posts/'
];

export const ignoreWords = [
  'a',
  'the',
  'with',
  'then',
  'they',
  'about',
  'at',
  'be',
  'of',
  'for',
  '&',
  'what',
  "don't",
  'no',
  'to',
  'some',
  'my',
  'your',
  'it',
  'is',
  'you',
  'them',
  'they',
  'here',
  "here's",
  'can',
  'and',
  'I',
  'on',
  'are',
  'in',
  'that',
  'after',
  '|',
  'by',
  'should',
  'why',
  'take',
  'made',
  'has',
  'an',
  'if',
  'but'
];

function removeSpecialChars(str) {
  return str.replace(/[^\w\s]/gi, '');
}

function fixTags(tags) {
  return tags
    .map((tag) => {
      if (!tag) {
        return null;
      }
      const tagSplit = tag.match(/[A-Z][a-z]+/g);
      if (tagSplit) {
        return _.flatten(
          tagSplit
            .map((ts) => removeSpecialChars(ts))
            .join(' ')
            .toLowerCase()
            .split(' ')
        );
      }
      return tag;
    })
    .filter((t) => t);
}

function getTagsFromTitle(title) {
  if (title) {
    const lCase = title.toLowerCase();
    const lSplit = lCase.split(' ');
    return _.flatten(
      lSplit
        .map((t) => removeSpecialChars(t).toLowerCase())
        .filter((t) => ignoreWords.indexOf(t) < 0)
        .map((t) => t.split(' '))
    ).filter((t) => ignoreWords.indexOf(t) < 0);
  }
  return '';
}

export function getArticles(pages) {
  return pages
    .map((page) => {
      if (!page) {
        return null;
      }
      if (ignorepaths.indexOf(page?.name) >= 0) {
        return null;
      }
      if (page.url.indexOf('/articles/') >= 0) {
        const lTabs = page.frontmatter.tags?.map((tag) => tag.toLowerCase()) || [];
        const titleTags = getTagsFromTitle(page.frontmatter.title);
        const excerpt = getTagsFromTitle(page.frontmatter.excerpt);
        return {
          url: withPrefix(page.url),
          name: page.frontmatter.title,
          tags: _.uniq([...lTabs, ...titleTags, ...excerpt].filter((t) => t)),
          type: 'post',
          image: page.frontmatter.image,
          imageAlt: page.frontmatter.image_alt
        };
      }
      return null;
    })
    .filter((p) => p);
}

export function getNews(news) {
  return news.map((n) => {
    const tags = fixTags(n.category);
    const title = getTagsFromTitle(n.title);
    const desc = getTagsFromTitle(n.description);
    return {
      url: n.url,
      name: n.title,
      tags: _.uniq([...tags, ...title, ...desc].filter((t) => t)),
      image: n.image || null,
      imageAlt: `${n.site} - ${tags?.join(', ') || ''}`,
      source: n.site || null,
      type: 'news'
    };
  });
}

export function getLists(lists) {
  return lists.map((list) => {
    const title = getTagsFromTitle(list.title);
    const desc = getTagsFromTitle(list.bestOfListSummary);
    const items = _.uniq(
      _.flatten(
        list.listItems.map((l) => {
          return [l.listItemTitle.toLowerCase(), getTagsFromTitle(l.listItemNote)];
        })
      )
    );
    return {
      url: withPrefix(`/lists#${list.listId}`),
      name: list.listTitle,
      tags: _.uniq([...title, ...desc, ...items].filter((t) => t)),
      type: 'list',
      image: null,
      imageAlt: null
    };
  });
}
