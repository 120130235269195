import React from 'react';
import { ShareButtons } from '.';
import ThemeContext from '../context/ThemeContext';
import { classNames, Link, withPrefix } from '../utils';
import LazyImage from './LazyImage';

export default class PostSiderail extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    const articles = this.props.pages.filter((page) => {
      return (
        page.relativeDir === 'articles' &&
        page.url.indexOf('/category/') < 0 &&
        page.url.indexOf('author/') < 0 &&
        page.url !== '/articles/' &&
        page.base !== this.props.current.base
      );
    });
    const sorted = articles.sort((a, b) => {
      const aDate = new Date(a.frontmatter.date.replace(/-/g, '/'));
      const bDate = new Date(b.frontmatter.date.replace(/-/g, '/'));

      if (aDate > bDate) {
        return -1;
      }
      if (aDate < bDate) {
        return 1;
      }
      return 0;
    });
    this.state.recents = sorted.slice(0, 3);
    this.state.title = this.props.current.frontmatter.title || 'Tinfoil My Life';
    this.state.sections = [];
    this.jumpToSection = this.jumpToSection.bind(this);
  }

  componentDidMount() {
    const heads = document.querySelectorAll('h3');
    this.setState({ sections: Array.from(heads).map((sec) => sec.textContent) });
  }

  jumpToSection(section) {
    const sections = document.querySelectorAll('h3');
    sections.forEach((sec, index) => {
      if (sec.textContent === section) {
        const y = sec.getBoundingClientRect().top + window.scrollY - 10;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    });
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={classNames('post-siderail', { dark: theme.darkMode })}>
            {this.state.sections?.length && (
              <div
                className={classNames('post-siderail--section', 'jump-to-container', {
                  dark: theme.dark
                })}
              >
                <h5>Jump to Section</h5>
                <div className="post-siderail--section__container">
                  {this.state.sections.map((section, index) => {
                    return (
                      <div
                        className="jump-to-section"
                        onClick={() => this.jumpToSection(section)}
                        onKeyDown={() => this.jumpToSection(section)}
                        role="button"
                        tabIndex={index}
                        key={`jumpTo-${index}`}
                      >
                        {section}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              className={classNames('post-siderail--section', 'hide-mobile', { dark: theme.dark })}
            >
              <h5>Share</h5>
              <div className="post-siderail--section__container">
                <ShareButtons location={this.props.location} title={this.state.title} />
              </div>
            </div>
            <div className={classNames('post-siderail--section', { dark: theme.dark })}>
              <h5>Recent Posts</h5>
              <div className="post-siderail--section__container">
                {this.state?.recents?.length &&
                  this.state.recents.map((post, index) => {
                    return (
                      <Link
                        to={withPrefix(post.url)}
                        className="siderail-item"
                        key={`recent-${index}`}
                      >
                        <div className="siderail-item--image">
                          <LazyImage
                            image={{ src: post.frontmatter.image, alt: post.frontmatter.image_alt }}
                          />
                        </div>
                        <div className="siderail-item--text">
                          <div className="siderail-item--text__type">
                            {post.frontmatter.post_type.post_type_title}
                          </div>
                          <div>{post.frontmatter.title}</div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
