import React from 'react';
import LazyImage from '../LazyImage';

export default class TelegramShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button telegram-share-button"
        title="Share to Telegram"
        href={`https://telegram.me/share/url?url=${this.props.encodedLocation}&text=${this.props.encodedTitle}`}
        target="_blank"
        rel="noreferrer nofollow"
        aria-label="Share to Telegram"
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962165-telegramsocial.png',
            alt: 'Telegram'
          }}
        />
      </a>
    );
  }
}
