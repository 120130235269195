import classNames from 'classnames';
import React from 'react';

export default class AnimatedQuotes extends React.Component {
  state = {
    quotesArr: [],
    currentIndex: 0,
    startAnimation: false,
    animationClass: 'animate-in',
    animationDuration: 10000,
    animateInOut: 0,
    animateRead: 0
  };

  constructor(props) {
    super(props);
    this.state.quotesArr = props.quotes;
    this.animationCycle = this.animationCycle.bind(this);
    this.initialQuoteAnimation = this.initialQuoteAnimation.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.changeQuote.bind(this), this.state.animationDuration);
    this.setState({
      animateInOut: 600,
      animateRead: this.state.animationDuration - 1200,
      startAnimation: true,
      animationClass: 'animate-read'
    });
    this.initialQuoteAnimation();
  }

  initialQuoteAnimation() {
    this.initialQuoteAnim = setTimeout(() => {
      this.setState({ animationClass: 'animate-out' });
    }, 9400);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearTimeout(this.initialQuoteAnim);
    clearTimeout(this.readAnim);
    clearTimeout(this.outAnim);
    this.intervalId = 0;
  }

  animationCycle() {
    this.setState({
      animationClass: 'animate-in'
    });
    this.readAnim = setTimeout(() => {
      this.setState({ animationClass: 'animate-read' });
    }, this.state.animateInOut);
    this.outAnim = setTimeout(() => {
      this.setState({ animationClass: 'animate-out' });
    }, this.state.animateRead);
  }

  changeQuote() {
    const newIndex =
      this.state.currentIndex + 1 === this.state.quotesArr.length ? 0 : this.state.currentIndex + 1;
    this.setState({ currentIndex: newIndex });
    this.animationCycle();
  }

  render() {
    return (
      <div className={classNames('animated-quotes', this.state.animationClass)}>
        <blockquote>
          {`"${this.state.quotesArr[this.state.currentIndex].quote_content}" `}
          <span className="quote-author">
            {`\u2014 ${this.state.quotesArr[this.state.currentIndex].quote_author_source}`}
          </span>
        </blockquote>
      </div>
    );
  }
}
