import React from 'react';
import { BsNewspaper } from '@react-icons/all-files/bs/BsNewspaper';
import { BsCardList } from '@react-icons/all-files/bs/BsCardList';
import { BsInfoCircleFill } from '@react-icons/all-files/bs/BsInfoCircleFill';
import { BsBook } from '@react-icons/all-files/bs/BsBook';
import { BsPersonSquare } from '@react-icons/all-files/bs/BsPersonSquare';
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';

const MobileNavIcons = ({ label }) => {
  if (label === 'News') {
    return <BsNewspaper className="mobile-nav-icon" />;
  }
  if (label === 'Articles') {
    return <BsBook className="mobile-nav-icon" />;
  }
  if (label === 'Lists') {
    return <BsCardList className="mobile-nav-icon" />;
  }
  if (label === 'About') {
    return <BsInfoCircleFill className="mobile-nav-icon" />;
  }
  if (label === 'Follow Me') {
    return <BsPersonSquare className="mobile-nav-icon" />;
  }
  if (label === 'Site Search') {
    return <BsSearch className="mobile-nav-icon" />;
  }
  return <></>;
};

export default MobileNavIcons;
