import React from 'react';
import LazyImage from '../LazyImage';

export default class DiasporaShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button diaspora-share-button"
        title="Share to Diaspora"
        href={`https://share.diasporafoundation.org/?title=${this.props.title}&url=${this.props.encodedLocation}`}
        target="_blank"
        rel="noreferrer nofollow"
        aria-label="Share to Diaspora"
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962177-diasporasocial.png',
            alt: 'Diaspora'
          }}
        />
      </a>
    );
  }
}
