import React from 'react';
import _ from 'lodash';

import { Link, withPrefix, classNames } from '../utils';
import Action from './Action';
import ThemeContext from '../context/ThemeContext';
import { ToggleSwitch } from '.';
import { mobileNavLinks, navLinks } from '../utils/navLinks';
import HoverDropdown from './commonComponents/HoverDropdown';

export default class Header extends React.Component {
  state = {
    navLinks: [],
    mobileNavLinks: [],
    screenWidth: null
  };
  constructor(props) {
    super(props);
    this.state.navLinks = navLinks;
    this.state.mobileNavLinks = mobileNavLinks;
    const isBrowser = typeof window !== 'undefined';
    this.state.screenWidth = isBrowser ? window.innerWidth : 0;
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser) {
      return;
    } else {
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser) {
      return;
    } else {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <header className={classNames('site-header', { dark: theme.dark })}>
            <div className="container container--lg">
              <nav
                className={classNames('navbar', { dark: theme.dark })}
                aria-label="Main Navigation"
              >
                <Link className="sr-only" to="#content">
                  Skip to main content
                </Link>
                <Link className="navbar__logo" to={withPrefix('/')}>
                  <img
                    src={withPrefix(
                      _.get(this.props, 'pageContext.site.siteMetadata.header.logo', null)
                    )}
                    alt={_.get(this.props, 'pageContext.site.siteMetadata.header.logo_alt', null)}
                  />
                </Link>
                <Link
                  className={classNames('h4', 'navbar__title', { dark: theme.dark })}
                  to={withPrefix('/')}
                >
                  {_.get(this.props, 'pageContext.site.siteMetadata.header.title', null)}
                </Link>
                {_.get(this.props, 'pageContext.site.siteMetadata.header.has_nav', null) && (
                  <React.Fragment>
                    <button
                      aria-label="Menu"
                      className={classNames(
                        'btn',
                        'btn--icon',
                        'btn--clear',
                        'navbar__menu-btn',
                        'js-nav-toggle',
                        { dark: theme.dark }
                      )}
                    >
                      <svg
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z" />
                      </svg>
                    </button>
                    <div className={classNames('navbar__menu', { dark: theme.dark })}>
                      <div className="navbar__scroller">
                        <div className="navbar__inner">
                          <button
                            aria-label="Close"
                            className={classNames(
                              'btn',
                              'btn--icon',
                              'btn--clear',
                              'navbar__close-btn',
                              'js-nav-toggle',
                              { dark: theme.dark }
                            )}
                          >
                            <svg
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"
                                fill={theme.dark ? '#ecf8f8' : '#233d4d'}
                              />
                            </svg>
                          </button>
                          <ul className="navbar__list menu">
                            <li key="toggle-switch">
                              <ToggleSwitch changeCb={theme.toggleDark} />
                            </li>
                            {this.state.screenWidth <= 800 &&
                              _.map(this.state.mobileNavLinks, (action, action_idx) => {
                                let pageUrl = _.trim(
                                  _.get(this.props, 'pageContext.url', null),
                                  '/'
                                );
                                let actionUrl = _.trim(_.get(action, 'url', null), '/');
                                return (
                                  <li
                                    key={action_idx}
                                    className={classNames('navbar__item', {
                                      'navbar__item--btn': _.get(action, 'style', null) !== 'link',
                                      'is-active': pageUrl === actionUrl,
                                      dark: theme.dark
                                    })}
                                  >
                                    <Action {...this.props} action={action} withIcon={true} />
                                  </li>
                                );
                              })}
                            {this.state.screenWidth > 800 && (
                              <>
                                <li key="desktop-nav-1" className={classNames('navbar__item')}>
                                  <HoverDropdown
                                    title="Content"
                                    links={this.state.navLinks.content}
                                    dark={theme.dark}
                                    key="dropdown-info"
                                    props={this.props}
                                  />
                                </li>
                                <li key="desktop-nav-2" className={classNames('navbar__item')}>
                                  <HoverDropdown
                                    title="Info"
                                    links={this.state.navLinks.info}
                                    dark={theme.dark}
                                    key="dropdown-site"
                                    props={this.props}
                                  />
                                </li>
                                {_.map(this.state.navLinks.other, (action, action_idx) => {
                                  let pageUrl = _.trim(
                                    _.get(this.props, 'pageContext.url', null),
                                    '/'
                                  );
                                  let actionUrl = _.trim(_.get(action, 'url', null), '/');
                                  return (
                                    <li
                                      key={action_idx}
                                      className={classNames('navbar__item', {
                                        'navbar__item--btn':
                                          _.get(action, 'style', null) !== 'link',
                                        'is-active': pageUrl === actionUrl,
                                        dark: theme.dark
                                      })}
                                    >
                                      <Action {...this.props} action={action} withIcon={true} />
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </nav>
            </div>
          </header>
        )}
      </ThemeContext.Consumer>
    );
  }
}
