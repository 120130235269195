import React, { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { classNames } from '../../utils';
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';

const SearchBar = ({ keyword, setKeyword, dark, ...props }) => {
  const handleChange = useCallback(
    (e) => {
      setKeyword(e.target.value);
    },
    [setKeyword]
  );

  const debouncedChange = useMemo(() => debounce(handleChange, 300), [handleChange]);

  useEffect(() => {
    debouncedChange.cancel();
  }, [debouncedChange]);

  return (
    <div className="search-bar-container">
      <input
        type="text"
        id="search-bar"
        key="search-1"
        value={keyword}
        placeholder="Search news, lists, and articles"
        name="search-value"
        onChange={debouncedChange}
        className={classNames('search-bar', { dark })}
      />
      <BsSearch className="search-bar--icon" />
    </div>
  );
};

export default SearchBar;
