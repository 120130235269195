import React from 'react';
import _ from 'lodash';
import scrollTo from 'gatsby-plugin-smoothscroll';
import classnames from 'classnames';
import ThemeContext from '../context/ThemeContext';
import { ImArrowUp } from '@react-icons/all-files/im/ImArrowUp';
import PaginationNavigation from './PaginationNavigation';
import { NewsFeedCard } from '.';

export default class NewsFeedSection extends React.Component {
  state = {
    scrolled: 0,
    showScrollButton: false,
    news: []
  };
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
      showScrollButton: false,
      news: _.get(this.props, 'pageContext.frontmatter.newsLinks', null)
    };
    this.listenToScroll = this.listenToScroll.bind(this);
    this.cardClicked = this.cardClicked.bind(this);
  }

  listenToScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;

    this.setState({
      scrolled: scrolled * 100,
      showScrollButton: winScroll > 300
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  cardClicked(url) {
    window.open(url).focus();
  }

  render() {
    const limit = _.get(this.props, 'pageContext.limit', null);
    const skip = _.get(this.props, 'pageContext.skip', null);
    const newsTruncated = this.state.news.slice(skip, skip + limit);
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div>
            <div className={classnames('news-feed', { dark: theme.dark })}>
              <button
                className={`scroll-to-top-button ${
                  this.state.showScrollButton ? 'show-scroll' : 'hide-scroll'
                }`}
                onClick={() => scrollTo('#site-wrap')}
              >
                <div>
                  <ImArrowUp alt="arrow up icon" />
                </div>
              </button>
              <div className={classnames('news-feed--content', { dark: theme.dark })}>
                {newsTruncated.map((item, index) => {
                  return (
                    <NewsFeedCard
                      item={item}
                      theme={theme}
                      index={index}
                      key={`newscard-${index}`}
                    />
                  );
                })}
              </div>
            </div>
            {_.get(this.props, 'pageContext.numberOfPages', 1) > 1 && (
              <PaginationNavigation
                total={_.get(this.props, 'pageContext.numberOfPages')}
                perPage={limit}
                nextPath={_.get(this.props, 'pageContext.nextPagePath')}
                previousPath={_.get(this.props, 'pageContext.previousPagePath')}
                current={_.get(this.props, 'pageContext.humanPageNumber')}
                baseUrl={_.get(this.props, 'pageContext.url')}
              />
            )}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
