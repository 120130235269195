import React from 'react';
import _ from 'lodash';

import Action from './Action';
import { classNames, htmlToReact } from '../utils';
import ThemeContext from '../context/ThemeContext';

export default class Footer extends React.Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <footer className={classNames('site-footer', { dark: theme.dark })}>
            <div className="container container--lg">
              {(_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) ||
                _.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null)) && (
                <div className="site-footer__nav">
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) && (
                    <ul className="site-footer__menu menu">
                      {_.map(
                        _.get(this.props, 'pageContext.site.siteMetadata.footer.nav_links', null),
                        (action, action_idx) => (
                          <li key={action_idx}>
                            <Action {...this.props} action={action} darkMode={theme.dark} />
                          </li>
                        )
                      )}
                    </ul>
                  )}
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null) && (
                    <ul className="site-footer__social menu">
                      {_.map(
                        _.get(
                          this.props,
                          'pageContext.site.siteMetadata.footer.social_links',
                          null
                        ),
                        (action, action_idx) => (
                          <li key={action_idx}>
                            <Action {...this.props} action={action} darkMode={theme.dark} />
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              )}
              <div className="site-footer__copyright">
                {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                  <span>
                    {htmlToReact(
                      _.get(this.props, 'pageContext.site.siteMetadata.footer.content', null)
                    )}
                  </span>
                )}
                {_.map(
                  _.get(this.props, 'pageContext.site.siteMetadata.footer.links', null),
                  (action, action_idx) => (
                    <Action
                      key={action_idx}
                      {...this.props}
                      action={action}
                      darkMode={theme.dark}
                    />
                  )
                )}
              </div>
              <div className="site-footer__association">
                {/*eslint-disable-next-line */}
                <a href="https://www.ontoplist.com/best-technology-websites/" target="_blank">
                  <img
                    src="https://www.ontoplist.com/images/ontoplist31.png?id=6012ed159684e"
                    alt=" Websites - OnToplist.com"
                    border="0"
                  />
                </a>
                {/*eslint-disable-next-line */}
                <a href="https://www.plazoo.com" target="_blank">
                  <img
                    src="https://www.plazoo.com/external/images/button_small1.gif"
                    border="0"
                    alt="RSS Search"
                  />
                </a>
              </div>
            </div>
          </footer>
        )}
      </ThemeContext.Consumer>
    );
  }
}
