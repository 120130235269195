import React from 'react';
import _ from 'lodash';
import { Link, withPrefix } from '../utils';

export default class ImageButtons extends React.Component {
  render() {
    let actions = _.get(this.props, 'section.image_buttons', null);
    const buttonLabels = actions.map((b) => b.label);
    const mindsButtonIndex = buttonLabels.indexOf('Minds');
    if (this.props.darkMode) {
      actions[mindsButtonIndex].button_link_image =
        'https://www.datocms-assets.com/40390/1622677795-minds-light.png';
    } else {
      actions[mindsButtonIndex].button_link_image =
        'https://www.datocms-assets.com/40390/1611322113-minds.png';
    }
    return _.map(actions, (action, index) => (
      <Link
        to={withPrefix(_.get(action, 'url', null))}
        key={`link-${index}`}
        {...(_.get(action, 'new_window', null) ? { target: '_blank' } : null)}
        {...(_.get(action, 'new_window', null) || _.get(action, 'no_follow', null)
          ? {
              rel:
                (_.get(action, 'new_window', null) ? 'noopener ' : '') +
                (_.get(action, 'no_follow', null) ? 'nofollow' : '')
            }
          : null)}
      >
        <img
          src={_.get(action, 'button_link_image')}
          className="button-link-image"
          alt={_.get(action, 'button_image_alt')}
        />
      </Link>
    ));
  }
}
