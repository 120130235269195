export const navLinks = {
  content: [
    {
      new_window: false,
      type: 'action',
      style: 'link',
      no_follow: false,
      url: '/articles',
      label: 'Articles',
      icon: 3 // BsBook
    },
    {
      new_window: false,
      type: 'action',
      style: 'link',
      no_follow: false,
      url: '/lists',
      label: 'Lists',
      icon: 1 // BsCardList
    },
    {
      label: 'News',
      new_window: false,
      no_follow: false,
      style: 'link',
      type: 'action',
      url: '/news',
      icon: 0 // BsNewspaper
    }
  ],
  info: [
    {
      new_window: false,
      type: 'action',
      style: 'link',
      no_follow: false,
      url: '/search',
      label: 'Site Search',
      icon: 5 // BsSearch
    },
    {
      new_window: false,
      type: 'action',
      style: 'link',
      no_follow: false,
      url: '/about',
      label: 'About',
      icon: 2 // BsInforCircleFill
    },
    {
      new_window: false,
      type: 'action',
      style: 'link',
      no_follow: false,
      url: '/follow',
      label: 'Follow Me',
      icon: 4 // BsPersonSquare
    }
  ],
  other: [
    {
      new_window: false,
      type: 'action',
      style: 'primary',
      no_follow: false,
      url: '/help-me-out',
      label: 'Help Me Out!'
    }
  ]
};

export const mobileNavLinks = [
  {
    new_window: false,
    type: 'action',
    style: 'link',
    no_follow: false,
    url: '/articles',
    label: 'Articles'
  },
  {
    new_window: false,
    type: 'action',
    style: 'link',
    no_follow: false,
    url: '/lists',
    label: 'Lists'
  },
  {
    label: 'News',
    new_window: false,
    no_follow: false,
    style: 'link',
    type: 'action',
    url: '/news'
  },
  {
    new_window: false,
    type: 'action',
    style: 'link',
    no_follow: false,
    url: '/search',
    label: 'Site Search'
  },
  {
    new_window: false,
    type: 'action',
    style: 'link',
    no_follow: false,
    url: '/about',
    label: 'About'
  },
  {
    new_window: false,
    type: 'action',
    style: 'link',
    no_follow: false,
    url: '/follow',
    label: 'Follow Me'
  },
  {
    new_window: false,
    type: 'action',
    style: 'primary',
    no_follow: false,
    url: '/help-me-out',
    label: 'Help Me Out!'
  }
];

export const isActiveDd = (title, path) => {
  const key = title.toLowerCase();
  const linkArr = navLinks[key]?.map((link) => link.url);
  return linkArr.indexOf(path) >= 0 || linkArr.indexOf(`${path}/`) >= 0;
};
