import React, { useState } from 'react';
import { classNames, Link } from '../../utils';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import { BsNewspaper } from '@react-icons/all-files/bs/BsNewspaper';
import { BsCardList } from '@react-icons/all-files/bs/BsCardList';
import { BsInfoCircleFill } from '@react-icons/all-files/bs/BsInfoCircleFill';
import { BsBook } from '@react-icons/all-files/bs/BsBook';
import { BsPersonSquare } from '@react-icons/all-files/bs/BsPersonSquare';
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import { isActiveDd } from '../../utils/navLinks';

const HoverDropdown = ({ title, links, dark, props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const linkIcons = [BsNewspaper, BsCardList, BsInfoCircleFill, BsBook, BsPersonSquare, BsSearch];
  const currentPath = props?.path;
  const isActiveOuter = isActiveDd(title, currentPath);

  return (
    <div
      className="hover-dropdown"
      key={`dd-link-container-${title}`}
      role="menu"
      tabIndex={0}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button
        className={classNames('hover-dropdown--button', { dark, 'is-active': isActiveOuter })}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {title}
        <BsChevronDown
          className={classNames('hover-dropdown--button__caret', { 'is-open': isOpen })}
        />
      </button>
      <div className={classNames('hover-dropdown--links', { dark })}>
        {links?.length &&
          links.map((link, index) => {
            const Icon = typeof link.icon === 'number' ? linkIcons[link.icon] : <></>;
            return (
              <React.Fragment key={`link-wrapper-${title}-${index}`}>
                <Link
                  to={link.url}
                  className={classNames('hover-dropdown--links__link', {
                    'with-border': index < links.length - 1,
                    dark
                  })}
                  tabIndex={0}
                >
                  <div
                    className={classNames('link-inner', {
                      dark,
                      'is-active': currentPath === link.url || currentPath === `${link.url}/`
                    })}
                  >
                    <Icon />
                    {link.label}
                  </div>
                </Link>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default HoverDropdown;
