import React from 'react';
import LazyImage from '../LazyImage';

export default class MeweShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button mewe-share-button"
        title="Share to MeWe"
        href={`https://mewe.com/share?link=${this.props.encodedLocation}`}
        target="_blank"
        rel="noreferrer nofollow"
        aria-label="Share to MeWe"
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962173-mewesocial.png',
            alt: 'MeWe'
          }}
        />
      </a>
    );
  }
}
