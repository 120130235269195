import React from 'react';
import LazyImage from '../LazyImage';

export default class MindsShareButton extends React.Component {
  render() {
    return (
      <a
        className="share-button minds-share-button"
        title="Share to Minds"
        href={`https://www.minds.com/newsfeed/subscribed?intentUrl=${this.props.encodedLocation}`}
        target="_blank"
        rel="noreferrer"
      >
        <LazyImage
          image={{
            src: 'https://www.datocms-assets.com/40390/1619962156-mindssocial.png',
            alt: 'Minds'
          }}
        />
      </a>
    );
  }
}
