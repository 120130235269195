import React from 'react';
import { BuyMeABeerButton } from '.';
import ThemeContext from '../context/ThemeContext';
import { classNames } from '../utils';
import LazyImage from './LazyImage';

export default class HelpMeOutCta extends React.Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className="help-cta">
            <div className="disclaimer">
              <b>Full disclosure</b>...I have this site registered with Brave Browser to be able to
              accept tips. This does earn me BAT tokens and, although that doesn't amount to much, I
              wanted to be upfront about that. I DO recommend Brave Browser on my site and it has
              nothing to do with this as I really do think highly of it as a browser. I just wanted
              that to be known!
            </div>

            <div className="help-cta--sub">
              <div className={classNames('htlp-cta--sub__beer', { dark: theme.dark })}>
                You can
                <BuyMeABeerButton />
              </div>
              <div className={classNames('help-cta--sub__rewards', { dark: theme.dark })}>
                <img
                  className="brave-rewards"
                  src="https://www.datocms-assets.com/40390/1611193664-battiny.png"
                  alt="Brave Rewards icon"
                />
                If you are using Brave Browser, you can tip me via the "Brave Rewards" icon at the
                top of the page on the right side of the URL bar.
              </div>
            </div>
            <div className="help-cta--referrals">
              <h3>Referral Links</h3>
              <p>
                Alternately, if you are interested in signing up for some of the services I use and
                mention in my posts, please use one of my referral links!
              </p>
              <table>
                <thead className={classNames({ dark: theme.dark })}>
                  <tr>
                    <th>Service</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a
                        href="https://www.etesync.com/"
                        rel="nofollow, noreferrer"
                        target="_blank"
                        className={classNames({ dark: theme.dark, 'dark-link': theme.dark })}
                      >
                        EteSync
                      </a>
                    </td>
                    <td>
                      Sign up, then add referral code <b>r_4Nv5</b> when you pick a paid plan and I
                      get 2 weeks free.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="https://www.minds.com/register?referrer=tinfoileverything"
                        rel="nofollow, noreferrer"
                        target="_blank"
                        className={classNames({ dark: theme.dark, 'dark-link': theme.dark })}
                      >
                        Minds
                      </a>
                    </td>
                    <td>Just use the link to join the Minds social network!</td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="https://www.coinbase.com/join/gauthi_j6"
                        rel="nofollow, noreferrer"
                        target="_blank"
                        className={classNames({ dark: theme.dark, 'dark-link': theme.dark })}
                      >
                        Coinbase
                      </a>
                    </td>
                    <td>
                      Just use the link to sign up for a crypto exchange account on Coinbase and we
                      both get $10 of Bitcoin once you buy $100 in crypto!
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="https://dnt.abine.com/#/ref_register/pySga6wtt"
                        rel="nofollow, noreferrer"
                        target="_blank"
                        className={classNames({ dark: theme.dark, 'dark-link': theme.dark })}
                      >
                        Blur
                      </a>
                    </td>
                    <td>
                      Just use the link to sign up Blur and start using virtual credits cards to
                      protect your payment information from being leaked or hacked.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="help-cta--crypto">
              <h3>Donate Cryptocurrency</h3>
              <p>You can even donate some cryptocurrency if you like!</p>
              <div className="help-cta--crypto__coin">
                <div className="coin-inner">
                  <LazyImage
                    image={{
                      src: 'https://www.datocms-assets.com/40390/1613667023-bitcoin.png',
                      alt: 'Bitcoin logo'
                    }}
                  />
                  <span>Bitcoin wallet address</span>
                </div>
                <div className="coin-inner">3JGibP3GhRnd4pQeqYNBX7LobYDJtJ4N9e</div>
              </div>
              <br></br>
              <div className="help-cta--crypto__coin">
                <div className="coin-inner">
                  <LazyImage
                    image={{
                      src: 'https://www.datocms-assets.com/40390/1613667018-ethereum.png',
                      alt: 'Ethereum logo'
                    }}
                  />
                  <span>Ethereum wallet address</span>
                </div>
                <div className="coin-inner">0x8160B38684Ee7838bA41c1cc33429BE48296A539</div>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
