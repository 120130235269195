import React from "react";

export default class BuyMeABeerButton extends React.Component {
  render() {
    return (
      <a
        href="https://www.buymeacoffee.com/tinfoilmylife"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <img
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a beer&emoji=🍺&slug=tinfoilmylife&button_colour=f37f2d&font_colour=FFFFFF&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
          alt="buy me a beer imagery and text"
        />
      </a>
    );
  }
}
