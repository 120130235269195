import React from 'react';
import { classNames, Link } from '../../utils';
import LazyImage from '../LazyImage';

const SearchResult = ({ item, which, dark }) => {
  const errorImage = 'https://www.datocms-assets.com/40390/1629888680-feedimage.png';
  return (
    <div className={classNames('search-result card', { dark })}>
      {which === 'news' && (
        <a href={item.url} target="__blank" className={classNames('search-result--link', { dark })}>
          <div className={classNames('search-result--link__content', { dark })}>
            {item.image && (
              <LazyImage
                image={{
                  src: item.image,
                  alt: item.imageAlt
                }}
                errorImage={{
                  src: errorImage,
                  alt: 'feed icon'
                }}
              />
            )}
            <div className={classNames('search-result--title', { dark })}>{item.name}</div>
          </div>
        </a>
      )}
      {which !== 'news' && (
        <Link to={item.url} className={classNames('search-result--link', { dark })}>
          <div className={classNames('search-result--link__content', { dark })}>
            {item.image && (
              <LazyImage
                image={{
                  src: item.image,
                  alt: item.imageAlt
                }}
                errorImage={{
                  src: errorImage,
                  alt: 'feed icon'
                }}
              />
            )}
            <div className={classNames('search-result--title', { dark })}>{item.name}</div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default SearchResult;
