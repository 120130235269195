import React from 'react';
import _ from 'lodash';
import { ImArrowRight } from '@react-icons/all-files/im/ImArrowRight';
import { ImArrowLeft } from '@react-icons/all-files/im/ImArrowLeft';

import { getPages, Link, withPrefix } from '../utils';

export default class BlogPrevNext extends React.Component {
  truncateText(text) {
    if (text) {
      const maxLength = 50;
      const willNeedDots = text.length > maxLength;
      const limit = willNeedDots ? maxLength - 3 : maxLength;
      const truncated = text.substring(0, limit);
      return `${truncated}${willNeedDots ? '...' : ''}`;
    }
    return '';
  }

  render() {
    const postsAll = getPages(this.props.currentPage.pages, '/articles');
    const postsSorted = _.orderBy(postsAll, 'frontmatter.date', 'desc').filter(
      (post) => post.url.indexOf('/category/') < 0 && post.url.indexOf('/author/') < 0
    );
    const pLast = postsSorted.length - 1;
    const currentIndex = postsSorted.map((p) => p.url).indexOf(this.props.currentPage.url);

    const next = postsSorted[currentIndex > 0 ? currentIndex - 1 : pLast];
    const prev = postsSorted[currentIndex < pLast ? currentIndex + 1 : 0];
    return (
      <div className="blog-prev-next">
        <Link
          className="blog-prev-next--box"
          key="prev"
          to={withPrefix(prev.url)}
          title={prev.frontmatter.title}
        >
          <div
            className="blog-prev-next--box__inner"
            style={{ backgroundImage: `url(${prev.frontmatter.image})` }}
          />
          <div className="blog-prev-next--box__container">
            <div className="blog-prev-next__arrow left">
              <ImArrowLeft />
            </div>
            <div className="blog-prev-next__post">{this.truncateText(prev.frontmatter.title)}</div>
          </div>
        </Link>
        <div className="blog-prev-next--box__spacer" />
        <Link
          className="blog-prev-next--box"
          key="next"
          to={withPrefix(next.url)}
          title={next.frontmatter.title}
        >
          <div
            className="blog-prev-next--box__inner"
            style={{ backgroundImage: `url(${next.frontmatter.image})` }}
          />
          <div className="blog-prev-next--box__container">
            <div className="blog-prev-next__post">{this.truncateText(next.frontmatter.title)}</div>
            <div className="blog-prev-next__arrow right">
              <ImArrowRight />
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
