import React from 'react';
import ThemeContext from '../context/ThemeContext';
import { classNames, getTheme } from '../utils';

export default class ToggleSwitch extends React.Component {
  state = {
    isDark: false
  };
  constructor(props) {
    super(props);
    this.toggleClicked = this.toggleClicked.bind(this);
  }

  toggleClicked() {
    this.setState({ isDark: !this.state.isDark });
    this.props.changeCb();
  }

  componentDidMount() {
    const darkMode = JSON.parse(getTheme());
    // if (darkMode) {
    //   this.toggleClicked();
    // }
    this.setState({ isDark: darkMode });
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className="toggle-switch__wrapper">
            <div className={`theme-label__first ${theme.dark ? ' dark' : ''}`}>☀</div>
            <label
              className={classNames('toggle-switch', { dark: theme.dark })}
              title="Toggle site theme"
            >
              <input type="checkbox" onChange={this.toggleClicked} checked={this.state.isDark} />
              <span className="slider round"></span>
            </label>
            <div className={`theme-label__last ${theme.dark ? ' dark' : ''}`}>☾</div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
