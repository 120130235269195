import Action from './Action';
import AnimatedQuotes from './AnimatedQuotes';
import BestOfListsSection from './BestOfListsSection';
import BlogDifficultyBadge from './BlogDifficultyBadge';
import BlogFeedItemFilter from './BlogFeedItemFilter';
import BlogFeedSection from './BlogFeedSection';
import BlogHeaderMeta from './BlogHeaderMeta';
import BlogPostCategories from './BlogPostCategories';
import BlogPostFeedItem from './BlogPostFeedItem';
import BlogPostTags from './BlogPostTags';
import BlogPrevNext from './BlogPrevNext';
import BuyMeABeerButton from './BuyABeerButton';
import ContactSection from './ContactSection';
import ContentSection from './ContentSection';
import CtaSection from './CtaSection';
import DiasporaShareButton from './ShareButtons/DiasporaShareButton';
import EmailShareButton from './ShareButtons/EmailShareButton';
import FeaturesSection from './FeaturesSection';
import FollowSection from './FollowSection';
import Footer from './Footer';
import Header from './Header';
import HelpMeOutCta from './HelpMeOutCta';
import HeroSection from './HeroSection';
import HoverDropdown from './commonComponents/HoverDropdown';
import ImageButtons from './ImageButtons';
import LazyImage from './LazyImage';
import MeweShareButton from './ShareButtons/MeweShareButton';
import MindsShareButton from './ShareButtons/MindsShareButton';
import MobileNavIcons from './MobileNavIcons';
import NewsFeedCard from './NewsFeedCard';
import NewsFeedSection from './NewsFeedSection';
import ParlerShareButton from './ShareButtons/ParlerShareButton';
import PaginationNavigation from './PaginationNavigation';
import PostSiderail from './PostSiderail';
import Ratings from './Ratings';
import SearchBar from './commonComponents/SearchBar';
import SearchPageSection from './SearchPageSection';
import SearchResult from './commonComponents/SearchResult';
import SectionActions from './SectionActions';
import ShareButtons from './ShareButtons/ShareButtons';
import TeamSection from './TeamSection';
import TelegramShareButton from './ShareButtons/TelegramShareButton';
import ToggleSwitch from './ToggleSwitch';
import Layout from './Layout';

export {
  Action,
  AnimatedQuotes,
  BestOfListsSection,
  BlogDifficultyBadge,
  BlogFeedItemFilter,
  BlogFeedSection,
  BlogHeaderMeta,
  BlogPostCategories,
  BlogPostFeedItem,
  BlogPostTags,
  BlogPrevNext,
  BuyMeABeerButton,
  ContactSection,
  ContentSection,
  CtaSection,
  DiasporaShareButton,
  EmailShareButton,
  FeaturesSection,
  FollowSection,
  Footer,
  Header,
  HelpMeOutCta,
  HeroSection,
  HoverDropdown,
  ImageButtons,
  LazyImage,
  MeweShareButton,
  MindsShareButton,
  MobileNavIcons,
  NewsFeedCard,
  NewsFeedSection,
  PaginationNavigation,
  ParlerShareButton,
  PostSiderail,
  Ratings,
  SearchBar,
  SearchPageSection,
  SearchResult,
  SectionActions,
  ShareButtons,
  TeamSection,
  TelegramShareButton,
  ToggleSwitch,
  Layout
};

const modules = {
  Action,
  AnimatedQuotes,
  BestOfListsSection,
  BlogDifficultyBadge,
  BlogFeedItemFilter,
  BlogFeedSection,
  BlogHeaderMeta,
  BlogPostCategories,
  BlogPostFeedItem,
  BlogPostTags,
  BlogPrevNext,
  BuyMeABeerButton,
  ContactSection,
  ContentSection,
  CtaSection,
  DiasporaShareButton,
  EmailShareButton,
  FeaturesSection,
  FollowSection,
  Footer,
  Header,
  HelpMeOutCta,
  HeroSection,
  HoverDropdown,
  ImageButtons,
  LazyImage,
  MeweShareButton,
  MindsShareButton,
  MobileNavIcons,
  NewsFeedCard,
  NewsFeedSection,
  PaginationNavigation,
  ParlerShareButton,
  PostSiderail,
  Ratings,
  SearchBar,
  SearchPageSection,
  SearchResult,
  SectionActions,
  ShareButtons,
  TeamSection,
  TelegramShareButton,
  ToggleSwitch,
  Layout
};

export default modules;
