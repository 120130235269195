import React from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import LazyImage from './LazyImage';

export default class NewsFeedCard extends React.Component {
  render() {
    const item = this.props.item;
    const index = this.props.index;
    const theme = this.props.theme;
    const pDate = DateTime.fromISO(item.publishedDate).toFormat('DDD') || null;
    return (
      <div
        key={`news-${index}`}
        className={classnames('news-feed--content__card', 'card', {
          dark: theme.dark
        })}
      >
        <div className="card--title">
          <h4 className={classnames({ dark: theme.dark })}>{item.title}</h4>
        </div>
        <div className="card--image-wrapper">
          <LazyImage
            image={{ src: item.image }}
            errorImage={`https://via.placeholder.com/800x400/${theme.dark ? '272932' : 'ECF8F8'}/${
              theme.dark ? 'EFCA08' : 'FE7F2D'
            }/.webp?text=No+Image+Available`}
            className="card--image"
          />
          <div className="card--image-wrapper__date">{pDate}</div>
        </div>
        <div className={classnames('card--description', { dark: theme.dark })}>
          "{item.description}"
        </div>
        <div className={classnames('card--tags', { dark: theme.dark })}>
          {item.category.map((i, ind) => (
            <div key={`card-${this.index}-cat-${ind}`}>{`#${i}`}</div>
          ))}
        </div>
        <div className={classnames('card--info', { dark: theme.dark })}>
          <span className={classnames('card--info__label', { dark: theme.dark })}>
            Read full article on
          </span>
          <a href={item.url} target="__blank">
            {item.site}
          </a>
        </div>
      </div>
    );
  }
}
