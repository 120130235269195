import React from 'react';
import _ from 'lodash';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { ImArrowUp } from '@react-icons/all-files/im/ImArrowUp';
import { BsLink45Deg } from '@react-icons/all-files/bs/BsLink45Deg';
import classnames from 'classnames';
import ThemeContext from '../context/ThemeContext';
import LazyImage from './LazyImage';
import { GoStar } from '@react-icons/all-files/go/GoStar';
import { HiShieldCheck } from '@react-icons/all-files/hi/HiShieldCheck';

// this section is now fed from a JSON file in the 'data' directory
export default class BestOfListsSection extends React.Component {
  state = {
    lists: [],
    scrolled: 0,
    showScrollButton: false,
    scrollToId: ''
  };
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
      showScrollButton: false,
      lists: _.get(this.props, 'pageContext.frontmatter.sections[1]', null)
    };
    const lists = _.get(this.props, 'pageContext.frontmatter.sections[1]', null);
    const sorted = _.sortBy(lists.list_of_lists, 'listTitle');
    this.state.lists = { list_of_lists: sorted };
    this.scrollToList = this.scrollToList.bind(this);
    this.listenToScroll = this.listenToScroll.bind(this);
    this.listFlashDelay = null;
    this.listFlash = null;
    this.copyLink = this.copyLink.bind(this);
    this.flashHashed = this.flashHashed.bind(this);
  }

  flashHashed(index) {
    this.listFlashDelay = setTimeout(() => {
      this.setState({ scrollToId: index });
      this.listFlash = setTimeout(() => {
        this.setState({ scrollToId: null });
      }, 1800);
    }, 400);
  }

  scrollToList(id, index) {
    this.flashHashed(index);
    scrollTo(`#${id}`);
  }

  listenToScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;

    this.setState({
      scrolled: scrolled * 100,
      showScrollButton: winScroll > 300
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    if (window.location.hash) {
      try {
        const id = window.location.hash.substring(1);
        const listItems = this.state.lists.list_of_lists.map((l, index) => ({
          id: l.listId,
          index
        }));
        const navItem = listItems.filter((n) => n.id === id);
        this.flashHashed(navItem[0].index);
      } catch (e) {
        console.error(e);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
    clearTimeout(this.listFlashDelay);
    clearTimeout(this.listFlash);
  }

  copyLink(id) {
    const location = window.location;
    navigator.clipboard
      .writeText(`${location.origin}${location.pathname}#${id}`)
      .then(() => {
        return;
      })
      .catch((error) => {
        console.log('error copying to clipboard', error);
      });
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={classnames('lists-section', { dark: theme.dark })}>
            <h2>{this.state.lists.title}</h2>

            <div className="lists-section--index">
              {this.state.lists.list_of_lists.map((list, index) => {
                return (
                  <React.Fragment key={`list-${index}`}>
                    <div className="lists-section--index__column">
                      <div
                        className={classnames('index-link', { dark: theme.dark })}
                        role="button"
                        key={index}
                        onClick={() => this.scrollToList(list.listId, index)}
                        tabIndex={index}
                        onKeyDown={() => this.scrollToList(list.listId, index)}
                      >
                        {list.listTitle}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="lists-section--info">
              <div className="lists-section--info__line">
                <HiShieldCheck alt="shield icon" /> means this is something I use.
              </div>
              <div className="lists-section--info__line">
                <GoStar alt="star icon" /> means I use this and like it a lot!
              </div>
            </div>
            <button
              className={`scroll-to-top-button ${
                this.state.showScrollButton ? 'show-scroll' : 'hide-scroll'
              }`}
              onClick={() => scrollTo('#site-wrap')}
            >
              <div>
                <ImArrowUp alt="arrow up icon" />
              </div>
            </button>
            <div className="lists-section--wrapper">
              <div className="lists-section--container">
                {this.state?.lists?.list_of_lists?.map((list, index) => (
                  <div
                    className={classnames('lists-section--container__item', 'card', {
                      'scroll-to-flash':
                        this.state.scrollToId !== null && index === this.state.scrollToId,
                      dark: theme.dark
                    })}
                    key={`list-item-${index}`}
                    id={list.listId}
                  >
                    <div className="list-item-header">
                      <h4>{list.listTitle}</h4>
                      <button
                        className={classnames('icon-button', 'lists-section--hyperlink-button', {
                          dark: theme.dark
                        })}
                        aria-label="Copy link to this list"
                        onClick={() => this.copyLink(list.listId)}
                        title="Copy link to this list"
                      >
                        <BsLink45Deg alt="link icon" />
                      </button>
                    </div>
                    <p>{list.bestOfListSummary}</p>
                    <table className={classnames('list-item-table', { dark: theme.dark })}>
                      <thead>
                        <tr>
                          <th> </th>
                          <th>Icon</th>
                          <th>Site/Service</th>
                          <th>Link</th>
                          <th>Tagline or Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.listItems.map((item, ind) => (
                          <tr key={`${item.listId}-${ind}`}>
                            <td>
                              {item.usedByTfe &&
                                (item.TfeFave ? (
                                  <GoStar alt="star icon" />
                                ) : (
                                  <HiShieldCheck alt="shield icon" />
                                ))}
                            </td>
                            <td>
                              {item.listItemImage && (
                                <LazyImage
                                  image={{
                                    src: item.listItemImage.url,
                                    alt: item.listItemImageAltText
                                  }}
                                />
                              )}
                            </td>
                            <td>{item.listItemTitle}</td>
                            <td>
                              <a href={item.listItemLink} target="_blank" rel="noreferrer">
                                {item.listItemLink}
                              </a>
                            </td>
                            <td>{item.linkItemNote}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mobile-table-alternative">
                      {list.listItems.map((item, ind) => (
                        <div
                          className={classnames('mobile-table-alternative--item', {
                            dark: theme.dark
                          })}
                          key={`mobile-${ind}`}
                        >
                          <div className="mobile-table-alternative--item__top">
                            <LazyImage
                              image={{
                                src: item.listItemImage.url,
                                alt: item.listItemImageAltText
                              }}
                            />
                            {item.listItemTitle}
                            <div className="tfe-icon">
                              {item.usedByTfe && (item.TfeFave ? <GoStar /> : <HiShieldCheck />)}
                            </div>
                          </div>
                          <div>
                            <a
                              href={item.listItemLink}
                              className={classnames({ dark: theme.dark, 'dark-link': theme.dark })}
                            >
                              {item.listItemLink}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              className={`scroll-to-top-button ${
                this.state.showScrollButton ? 'show-scroll' : 'hide-scroll'
              }`}
              onClick={() => scrollTo('#site-wrap')}
            >
              <div>
                <ImArrowUp alt="arrow up icon" />
              </div>
            </button>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
