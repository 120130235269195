import React from 'react';
import { FaAngleDoubleRight } from '@react-icons/all-files/fa/FaAngleDoubleRight';
import { FaAngleDoubleLeft } from '@react-icons/all-files/fa/FaAngleDoubleLeft';
import { classNames, Link, withPrefix } from '../utils';
import ThemeContext from '../context/ThemeContext';

export default class PaginationNavigation extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    const numbers = Array.apply(null, new Array(this.props.total)).map((item, index) => index + 1);
    this.state.numbers = numbers;
  }

  getLink(num) {
    return (
      <Link
        to={num === 1 ? this.props.baseUrl : withPrefix(`${this.props.baseUrl}${num}`)}
        key={`pager-${num}`}
        disabled={num === this.props.current}
        className={`pagination-button ${num === this.props.current ? 'active' : ''}`}
      >
        {num}
      </Link>
    );
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={classNames('pagination', { dark: theme.dark })}>
            <div className="pagination--arrow left-arrow">
              <Link
                to={withPrefix(this.props.previousPath)}
                disabled={this.props.current === 1}
                className={`pagination-button ${this.props.current === 1 ? 'disabled' : ''}`}
              >
                <FaAngleDoubleLeft />
              </Link>
            </div>
            <div className="pagination__numbers">
              {/* 1 - 5 if 5 or less pages but more than 2 pages; 2 pages or less just gets arrows */}
              {this.props.total > 2 &&
                this.props.total <= 5 &&
                this.state.numbers.map((number) => {
                  return this.getLink(number);
                })}

              {/* 1 - 3 or 1 - 4 if more than 5 numbers and current is less than 5 */}
              {this.props.total > 5 &&
                this.state.numbers
                  .map((number) => {
                    if (
                      number === 1 ||
                      (number === 3 && this.props.current === 1) ||
                      (this.props.current < 4 && Math.abs(this.props.current - number) <= 1)
                    ) {
                      return this.getLink(number);
                    }
                    return null;
                  })
                  .filter((item) => item)}

              {/* first dots only shown if total is more than five and current is more than 3 */}
              {this.props.total > 5 && this.props.current > 3 && (
                <div className="pagination__numbers--dots">...</div>
              )}

              {/* middle section shown if total more than 5, current is greater than 3, and number is within 1 of current */}
              {this.props.total > 5 &&
                this.props.current > 3 &&
                this.props.total - this.props.current > 2 &&
                this.state.numbers
                  .map((number) => {
                    if (Math.abs(this.props.current - number) <= 1) {
                      return this.getLink(number);
                    }
                    return null;
                  })
                  .filter((item) => item)}

              {/* second section of dots shown if middle or fist section in use and current is at least 4 away from last */}
              {this.props.total > 5 && this.props.total - this.props.current > 2 && (
                <div className="pagination__numbers--dots">...</div>
              )}

              {/* last section for when total is more than 5 and current is within 4 of end */}
              {this.props.total > 5 &&
                this.props.total - this.props.current <= 2 &&
                this.state.numbers
                  .map((number) => {
                    if (number >= this.props.total - 3 && number !== this.props.total) {
                      return this.getLink(number);
                    }
                    return null;
                  })
                  .filter((item) => item)}

              {/* last one shown if total is more than 5 */}
              {this.props.total > 5 && this.getLink(this.props.total)}
            </div>
            <div className="pagination--arrow right-arrow">
              <Link
                to={withPrefix(this.props.nextPath)}
                disabled={this.props.current === this.props.total}
                className={`pagination-button ${
                  this.props.current === this.props.total ? 'disabled' : ''
                }`}
              >
                <FaAngleDoubleRight />
              </Link>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
