import React from 'react';
import classNames from 'classnames';

import {
  MindsShareButton,
  ParlerShareButton,
  MeweShareButton,
  TelegramShareButton,
  DiasporaShareButton,
  EmailShareButton
} from '../';

export default class ShareButtons extends React.Component {
  state = {
    encodedUrl: '#'
  };
  constructor(props) {
    super(props);
    this.state.encodedUrl = encodeURIComponent(this.props.location);
    this.state.encodedTitle = encodeURI(this.props.title);
  }
  render() {
    return (
      <div className={classNames('alt-share-buttons', { 'alt-share-vertical': false })}>
        {this.props.shareTitle && (
          <div className="share-title">
            <h4>SHARE:</h4>
          </div>
        )}
        <MindsShareButton encodedLocation={this.state.encodedUrl} />
        <MeweShareButton encodedLocation={this.state.encodedUrl} />
        <ParlerShareButton
          encodedLocation={this.state.encodedUrl}
          encodedTitle={this.state.encodedTitle}
        />
        <TelegramShareButton
          encodedLocation={this.state.encodedUrl}
          encodedTitle={this.state.encodedTitle}
        />
        <DiasporaShareButton encodedLocation={this.state.encodedUrl} title={this.props.title} />
        <EmailShareButton encodedLocation={this.state.encodedUrl} title={this.props.title} />
      </div>
    );
  }
}
