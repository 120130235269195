import React from 'react';
import _ from 'lodash';
import scrollTo from 'gatsby-plugin-smoothscroll';
import classnames from 'classnames';
import ThemeContext from '../context/ThemeContext';
import { ImArrowUp } from '@react-icons/all-files/im/ImArrowUp';
import { getArticles, getLists, getNews } from '../data/searchHelpers';
import SearchBar from './commonComponents/SearchBar';
// import { withPrefix } from '../utils';
import SearchResult from './commonComponents/SearchResult';

export default class SearchPageSection extends React.Component {
  state = {
    scrolled: 0,
    showScrollButton: false,
    searchable: [],
    pagesSearch: [],
    searchWord: ''
  };
  constructor(props) {
    super(props);
    this.listenToScroll = this.listenToScroll.bind(this);
    this.searchContent = this.searchContent.bind(this);
    this.state = {
      scrolled: 0,
      showScrollButton: false,
      searchable: [],
      pagesSearch: [],
      keyword: ''
    };
  }

  listenToScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;

    this.setState({
      scrolled: scrolled * 100,
      showScrollButton: winScroll > 300
    });
  }

  searchContent(keyword) {
    const kwLower = keyword ? keyword?.toLowerCase() : null;
    this.setState({ kwLower });
    if (kwLower?.length) {
      const results = this.state.searchable.filter(
        (r) => r.tags.filter((t) => t.indexOf(kwLower) >= 0).length || false
      );
      const sortedResults =
        results?.reduce((acc, obj) => {
          if (!acc[obj.type]) {
            acc[obj.type] = [obj];
          } else {
            acc[obj.type].push(obj);
          }
          return acc;
        }, {}) || {};
      this.setState({ pagesSearch: sortedResults });
    } else {
      this.setState({ pagesSearch: {} });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    const pages = _.get(this.props, 'pageContext.pages', []);
    const articles = getArticles(pages);
    const news = getNews(_.get(this.props, 'pageContext.newsLinks', []));
    const lists = getLists(
      _.get(this.props, 'pageContext.site.data.bestOfListsData.listOfLists', [])
    );
    const indexes = [...articles, ...news, ...lists];
    this.setState({ searchable: indexes });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={classnames('search-page', { dark: theme.dark })}>
            <button
              className={`scroll-to-top-button ${
                this.state.showScrollButton ? 'show-scroll' : 'hide-scroll'
              }`}
              onClick={() => scrollTo('#site-wrap')}
            >
              <div>
                <ImArrowUp alt="arrow up icon" />
              </div>
            </button>
            <div className="search-container">
              <div className="search-container--bar-container">
                <SearchBar
                  keyword={this.state.searchWord}
                  setKeyword={this.searchContent}
                  dark={theme.dark}
                />
              </div>
              <div className="search-container--results">
                {this.state.pagesSearch.post?.length && (
                  <div
                    className={classnames('search-container--results__card posts', {
                      dark: theme.dark
                    })}
                  >
                    <h4>Posts</h4>
                    {this.state.pagesSearch.post.map((p, i) => {
                      return (
                        <SearchResult item={p} key={`post-${i}`} which="post" dark={theme.dark} />
                      );
                    })}
                  </div>
                )}
                {this.state.pagesSearch.list?.length && (
                  <div
                    className={classnames('search-container--results__card lists', {
                      dark: theme.dark
                    })}
                  >
                    <h4>Lists</h4>
                    {this.state.pagesSearch.list.map((p, i) => {
                      return (
                        <SearchResult item={p} key={`list-${i}`} which="list" dark={theme.dark} />
                      );
                    })}
                  </div>
                )}
                {this.state.pagesSearch.news?.length && (
                  <div
                    className={classnames('search-container--results__card news', {
                      dark: theme.dark
                    })}
                  >
                    <h4>News</h4>
                    {this.state.pagesSearch.news.map((p, i) => {
                      return (
                        <SearchResult item={p} key={`news-${i}`} which="news" dark={theme.dark} />
                      );
                    })}
                  </div>
                )}
                {this.state.keyword &&
                  !this.state.pagesSearch?.post?.length &&
                  !this.state.pagesSearch?.list?.length &&
                  !this.state.pagesSearch?.news?.length && (
                    <h3 style={{ marginTop: '2rem' }}>No results</h3>
                  )}
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
