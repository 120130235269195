import React from 'react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaClipboardList } from '@react-icons/all-files/fa/FaClipboardList';
import { FaRobot } from '@react-icons/all-files/fa/FaRobot';
import { FaRegClock } from '@react-icons/all-files/fa/FaRegClock';
import moment from 'moment-strftime';
import { BlogDifficultyBadge, BlogPostCategories } from '../components/index';

export default class BlogHeaderMeta extends React.Component {
  render() {
    return (
      <div className="post__header-meta">
        <div className="post__header-meta--row">
          <div className="categories header-meta-item">
            {this.props.categories && (
              <BlogPostCategories
                {...this.props}
                categories={this.props.categories}
                container_class={'post__meta'}
              />
            )}
          </div>
          <div className="header-meta-item spacer">
            <span>{` | `}</span>
          </div>
          <div className="info header-meta-item">
            <span>
              Posted{' '}
              <time dateTime={moment(this.props.postDate).strftime('%Y-%m-%d %H:%M')}>
                {moment(this.props.postDate).strftime('%B %d, %Y')}
              </time>
            </span>
          </div>
          <div className="header-meta-item spacer">
            <span>{` | `}</span>
          </div>
          <div className="header-meta-item read-time">
            <FaRegClock alt="clock icon" style={{ marginRight: '.3rem' }} />
            {this.props.readTime}
          </div>
          <div className="header-meta-item spacer">
            <span>{` | `}</span>
          </div>
          <div className="header-meta-item type">
            {this.props.type?.id === 'info' && <FaInfoCircle alt="information circle icon" />}
            {this.props.type?.id === 'tut' && <FaClipboardList alt="list icon" />}
            {this.props.type?.id === 'op' && <FaRobot alt="robot icon" />}
            <div>&nbsp; {this.props.type?.post_type_title}</div>
          </div>
        </div>
        {this.props.type?.id === 'tut' && (
          <div className="post__difficulty">
            <BlogDifficultyBadge post={this.props.difficulty} />
          </div>
        )}
      </div>
    );
  }
}
